import React from 'react';

export default function PageNotFound() {
  return (
    <div>
        <h1>404 Error</h1>
        <h1>Page Not Found</h1>
        <a href="/home">BACK TO HOME</a>
    </div>
  );
}