import { supabase } from '../supabase-client';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import './login.scss'

export default function UpdatePassword() {
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      const { data } = await supabase.auth.getSession()
      if (!data.session) {
        navigate("/home")
        return
      }
    }
    getSession()

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((state) => {
      if (state === 'SIGNED_IN')
        navigate("/")
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  function useUser() {
    console.log('useUser')
  }

  return (
    <main className="login">
        <div className="login-logo-container" onClick={() => window.location = '/'}>
          <img className="login-logo-img" src="image/home-logo.png" alt="logo"/>
        </div>
        <Auth 
          supabaseClient={supabase} 
          appearance={{ theme: ThemeSupa }} 
          providers={[]}
          magicLink='true'
          view="update_password"
          useUser = {useUser}/>
    </main>
  );
}