import { useNavigate } from "react-router-dom";
import RequestList from "./request-list";
import RequestListM from "./request-list-m";
import RequestDetail from "./request-detail";
import { useEffect, useState } from 'react'
import { supabase } from '../supabase-client';
import './account.scss';
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
import { useMediaQuery } from 'react-responsive'

export default function Account() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null)
  const [requests, setRequests] = useState([])
  const [selected, setSelected] = useState(0)
  const [currentRequest, setCurrentRequest] = useState({})
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' })

  async function onTypeformClose() {
    console.log('onTypeformClose')
    getData()
  }

  const typeform = createPopup('LqNx4JMK', {onClose: onTypeformClose})
  function onNewRequestClick() {
    typeform.toggle()
  }

  const getData = async () => {
    const { data } = await supabase.auth.getSession()
    if (!data.session) {
      navigate("/home")
      return
    }
      
    const {data: requests} = await supabase.from('requests').select()
    requests.sort((a, b) => {
      const date_a = new Date(a.created_at)
      const date_b = new Date(b.created_at)

      if (date_a > date_b)
        return -1
      if (date_a < date_b)
        return 1
      return 0
    })
    setRequests(requests)
    setCurrentRequest(requests[0])
    
    const { data: admins } = await supabase.from('admin').select()
    const isAdmin = admins.find(item => item.email === data.session.user.email) ? true : false
    setUser({
      email: data.session.user.email,
      isAdmin: isAdmin
    })

  }

  useEffect(() => {
    getData()

    supabase.auth.onAuthStateChange((event, session) => {
      console.log('onAuthStateChange: ' + event)
      if (event == 'PASSWORD_RECOVERY') {
        navigate('update-password')
      }
    })
  }, [])

  async function signOut() {
    await supabase.auth.signOut()
    navigate("/home");
  }
  
  function onRequestClick(requestId) {
    let index = requests.findIndex(item => item.id === requestId)
    setSelected(index)
    setCurrentRequest(requests[index])
  }

  function onRequestStatusUpdate(requestId, status) {
    const temp = requests.slice()
    temp.find(item => item.id == requestId).status = status
    setRequests(temp)
  }

  if (!user)
    return (<main></main>)

  if (!requests || requests.length == 0)
    return (
      <main className="account">
        <button className="btn-sign-in account-empty-btn-sign-out" onClick={signOut}>Sign Out</button>
        <div className="account-empty-content">
          <span className="account-empty-text"><span className="account-empty-create" onClick={onNewRequestClick}>Create</span> your first request</span>
        </div>
      </main> 
    )

  if (isSmallScreen) {
    return (
      <main className="account">
        <div className="content">
          <div className="account-nav"><button className="btn-sign-in" onClick={signOut}>Sign Out</button></div>
          <div className="request-container">
            <RequestListM requests={requests} selected={selected} onRequestClick={onRequestClick} user={user} onRequestStatusUpdate={onRequestStatusUpdate}/>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="account">
      <div className="content">
        <div className="account-nav"><button className="btn-sign-in" onClick={signOut}>Sign Out</button></div>
        <div className="request-container">
          <RequestList requests={requests} selected={selected} onRequestClick={onRequestClick}/>
          <RequestDetail request={currentRequest} user={user} key={currentRequest.id} onRequestStatusUpdate={onRequestStatusUpdate}/>
        </div>
      </div>
    </main>
  );
}