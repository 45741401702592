import { PopupButton } from '@typeform/embed-react'

export default function ButtonConnect() {
  return (
    <div className="btn-connect-wrapper">
      <PopupButton id="LqNx4JMK" className="btn-connect">
        <span className="txt-connect vertical-center">Get Connected</span>
        <img className="img-connect vertical-center" src="image/home-connect.png" alt="connect"/>
      </PopupButton>
    </div>
  );
}