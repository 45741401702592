export default function RequestItem({request, className, onRequestClick}) {
  let create_date = new Date(request.created_at).toLocaleDateString("en-US")
  let position = 'N/A'
  let company = 'N/A'
  if (request.form_response) {
    position = request.form_response.answers.find(item => item.field.id === 'Bz8CJ8JvJ63a').text
    company = request.form_response.answers.find(item => item.field.id === 'P7AWjDFj8pNh').text
  }
  let statusImg
  if (request.status === 'Completed') {
    statusImg = <img className="request-item-calendar" src="image/account-checked.png" alt="relax"/>
  } else if (request.status === 'Cancelled') {
    statusImg = <img className="request-item-calendar" src="image/account-cancelled.png" alt="relax"/>
  }

  return (
    <div className={`request-item ${className}`} onClick={() => onRequestClick(request.id)}>
      <h3 className="request-item-title">{position}/{company}</h3>
      <div className="request-item-content">
        <img className="request-item-calendar" src="image/account-calendar.png" alt="relax"/>
        <span className="request-item-date">{create_date}</span>
        <span className="request-item-divider">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <span className={`request-item-status ${request.status.toLowerCase().replace(' ', '-')}`}>{request.status}</span>
        {statusImg}
      </div>
    </div>
  );
}