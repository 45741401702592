import RequestItemM from "./request-item-m";
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'

export default function RequestListM({requests, selected, onRequestClick, user, onRequestStatusUpdate}) {
  const typeform = createPopup('LqNx4JMK')
  function onNewRequestClick() {
    typeform.toggle()
  }

  let requestsContent = requests.map((request, index)=> {
    return <RequestItemM key={request.id}  request={request} selected={index === selected} onRequestClick={onRequestClick} user={user} onRequestStatusUpdate={onRequestStatusUpdate}/>
  })

  return (
    <div className="request-list request-list-m">
      <div className="request-list-title-div" onClick={onNewRequestClick}> 
        <h2 className="request-list-title" >Request</h2>
        <span className="request-list-plus">+</span>
      </div>
      {requestsContent}
    </div>
  );
}