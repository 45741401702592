import ButtonConnect from "./button-connect"
import HighlightCard from './highlight-card';
import ProcessCard from './process-card';
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { supabase } from '../supabase-client';
import './home.scss'

export default function Home() {
  const navigate = useNavigate();
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const getSession = async () => {
      const { data } = await supabase.auth.getSession()
      if (data.session) 
        navigate("/")
      else
        setReady(true)
    }
    getSession()
  }, [])

  async function signIn() {
    navigate("/login")
  }

  if (!ready)
    return (<main></main>)
  return (
    <main className="home">
      <div className='home-section1'>
        <img className="home-logo-img" src="image/home-logo.png" alt="logo"/>
        <button className="btn-sign-in" onClick={signIn}>Sign In</button>
        <img className="home-img1" src="image/home-image1.png" alt="relax"/>
        <h2 className="home-text1"><span className="home-text-never">Never</span> Take The <span className="home-text-wrong">Wrong</span> Job Offer Again.</h2>
        <h3 className="home-text2">Closing in on your next role? We'll connect you with past employees from your target companies to give you confidence in your decision.</h3>
        <ButtonConnect/>
        <div className="highlight-container">
          <HighlightCard img="image/home-image2.png" title="Candid Insights" content="Connect with past employees to gain a more authentic perspective from the inside."/>
          <div className="line-break"></div>
          <HighlightCard img="image/home-image3.png" title="Peace of Mind" content="Bring your questions. We'll find someone who has the answers."/>
          <div className="line-break"></div>
          <HighlightCard img="image/home-image4.png" title="Get Answers Quickly" content="Never worry about sending a cold outreach message again. We'll bring the insiders to you."/>
        </div>
      </div>
      <h2 className="home-text3 text-center">Uncover Insights.&nbsp;<span className="blue">FAST.</span></h2>
      <ProcessCard img="image/home-form.png" color="#fed3d4" title="Fill out our intake form" content=" - company, role, what insights you're looking for...etc."/>
      <ProcessCard img="image/home-match.png" color="#bce4d1" title="Get matched" content=" - we'll find the right person based on your needs and set up a 1-1 consultation call for you."/>
      <ProcessCard img="image/home-calendar.png" color="#fddaaf" title="Complete your consultation call" content=" - no commitment or payments until your consultation is complete."/>
      <ProcessCard img="image/home-bulb.png" color="#c8dcfa" title="Make a confident decision" content=" - move forward with the new insights you've uncovered."/>
      <div className="text-center bottom-padding"><ButtonConnect/></div>
      <div className="divider"></div>
      <div className="copy-right-container">
          <div className="copy-right">©2023 InFinder. All rights reserved.</div>
          <div className="line-break"></div>
          <a className="email" href="mailto:rayc@infinder.app">rayc@infinder.app</a>
      </div>
    </main>
  );
}