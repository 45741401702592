import { supabase } from '../supabase-client';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useEffect, useState, Component } from 'react'
import { useNavigate } from "react-router-dom";
import './login.scss'
import $ from "jquery";

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      ready: false
    }
  }

  componentDidMount() {
    const getSession = async () => {
      const { data } = await supabase.auth.getSession()

      if (data.session) 
        window.open('/', '_self')
      else 
        this.setState({ready: true})
    }
    getSession()


    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((state) => {
      if (state === 'SIGNED_IN')
        window.open('/', '_self')
    })

    //$("#login-tou-text").html('123')

    setTimeout(() => {
      if ($('button').html() == 'Sign up') {
        $("#login-tou-text-prefix").css('display', 'inline')
      } else {
        $("#login-tou-text-prefix").css('display', 'none')
      }

      console.log($('button').html())
      $('.login').on('DOMSubtreeModified', () => {
        if ($('button').html() == 'Sign up') {
          $("#login-tou-text-prefix").css('display', 'inline')
        } else {
          $("#login-tou-text-prefix").css('display', 'none')
        }
      })
      
    }, 1)

  }

  render() {
    if(!this.state.ready)
      return <main></main>

    return <main className="login">
        <div className="login-logo-container" onClick={() => window.location = '/'}>
          <img className="login-logo-img" src="image/home-logo.png" alt="logo"/>
        </div>
        <Auth 
          supabaseClient={supabase} 
          appearance={{ theme: ThemeSupa }} 
          providers={[]}
          magicLink='true'/>

          <div className="login-tou-text">
            <span id="login-tou-text-prefix" className="login-tou-text-prefix">By clicking on sign-up, you agree to Infinder's&nbsp;</span>
            <a href="terms-of-use.html" target="_blank">Terms of Use</a>&nbsp;and&nbsp;
            <a href="privacy-policy.html" target="_blank">Privacy Policy</a>.
          </div>
    </main>
  }
}

export default Login