import RequestItem from "./request-item";
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'

export default function RequestList({requests, selected, onRequestClick}) {
  const typeform = createPopup('LqNx4JMK')
  function onNewRequestClick() {
    typeform.toggle()
  }

  let requestsContent = requests.map((request, index)=> {
    return <RequestItem key={request.id}  request={request} className={index === selected ? "selected" : ""} onRequestClick={onRequestClick}/>
  })

  return (
    <div className="request-list">
      <div className="request-list-title-div" onClick={onNewRequestClick}> 
        <h2 className="request-list-title" >Request</h2>
        <span className="request-list-plus">+</span>
      </div>
      {requestsContent}
    </div>
  );
}