import { useEffect, useState, useRef } from 'react'
import { supabase } from '../supabase-client';

const StripeButtonIds = {
  99: 'buy_btn_1NwbWrLAddGNp2Wwtv4PXmwj',
  149: 'buy_btn_1NxGoTLAddGNp2Www28IZMwC',
  199: 'buy_btn_1NxGq9LAddGNp2WwM5IzVyKr',
  299: 'buy_btn_1NwbCFLAddGNp2WwGdan8ggL'
}

export default function RequestDetail({request, user, onRequestStatusUpdate}) {
  const [isEditCall, setIsEditCall] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [zoomLink, setZoomLink] = useState(null)
  const [zoomDatetime, setZoomDatetime] = useState(null)
  const [calendarlyLink, setCalendarlyLink] = useState(null)
  const [requestStatus, setRequestStatus] = useState(request.status)
  const zoomDateRef = useRef(null);


  useEffect(() => {
    setIsAdmin(user.isAdmin)
    setIsEditCall(false)
    setZoomLink(request.zoom_link)
    setZoomDatetime(request.zoom_datetime ? new Date(request.zoom_datetime).toLocaleString() : null)
    setCalendarlyLink(request.calendarly_link)
  }, [request, user])

  async function onClickEditCall() {
    if (isEditCall) {
      const data = await supabase.from('requests').update({
        zoom_link: zoomLink,
        calendarly_link: calendarlyLink,
        zoom_datetime: zoomDateRef.current.value ? new Date(zoomDateRef.current.value).toISOString() : null
      })
      .eq('id', request.id)
    }

    setIsEditCall(!isEditCall)
    setZoomDatetime(zoomDateRef.current.value)
  }

  async function onSelectRequestStatus(e) {
    const value = e.target.value
    setRequestStatus(value)
    await supabase
    .from('requests')
    .upsert([
      { id: request.id,
        status: value
      },
    ])
    onRequestStatusUpdate(request.id, value)
  }

  let create_date = new Date(request.created_at).toLocaleDateString("en-US")
  let position = 'N/A'
  let company = 'N/A'
  let timeline = 'N/A'
  let price = 'N/A'
  let stripeKey = ''
  if (request.form_response) {
    position = request.form_response.answers.find(item => item.field.id === 'Bz8CJ8JvJ63a').text
    company = request.form_response.answers.find(item => item.field.id === 'P7AWjDFj8pNh').text
    let timelinePrice = request.form_response.answers.find(item => item.field.id === '6fihvYhNpTjy').choice.label
    timeline = timelinePrice.substring(0, timelinePrice.indexOf('$') - 1)
    price = timelinePrice.substring(timelinePrice.indexOf('$'), timelinePrice.length - 1)
    stripeKey = StripeButtonIds[price.substring(1)]
  }

  return (
    <div className="request-detail">
      <div className="request-detail-info request-detail-table">
        {isAdmin && <span className="request-detail-info-label">User:</span> }
        {isAdmin && <span className="request-detail-info-value">{request.email}</span>}
        <span className="request-detail-info-label">Request Number:</span>
        <span className="request-detail-info-value">#{request.id}</span>
        <span className="request-detail-info-label">Date:</span>
        <span className="request-detail-info-value">{create_date}</span>
        <span className="request-detail-info-label">Status:</span>
        <div className="request-detail-info-value">
          {isAdmin && 
          <select className={`status-value ${requestStatus.toLowerCase().replace(' ', '-')}`} 
            value={requestStatus}
            onChange={onSelectRequestStatus}>
            <option value="Open">OPEN</option>
            <option value="In Progress">IN PROGRESS</option>
            <option value="Completed">COMPLETED</option>
            <option value="Cancelled">CANCELLED</option>
          </select>
          }
          { !isAdmin && <span className={`status-value ${requestStatus.toLowerCase().replace(' ', '-')}`}>{requestStatus.toUpperCase()}</span>}
        </div>
      </div>
      <div className="request-detail-summary request-detail-table">
        <h3 className="request-detail-summary-title">Request Summary</h3>
        <div className="request-detail-summary-content">
          <span className="request-detail-label">Company</span>
          <span>{company}</span>
          <span className="request-detail-label">Position:</span>
          <span>{position}</span>
          <span className="request-detail-label">Timeline:</span>
          <span>{timeline}</span>
          <span className="request-detail-label">Price:</span>
          <span>{price}</span>
        </div>
      </div>
      <div className="request-detail-payment request-detail-table">
        <div className="request-detail-payment-header">
          <h3 className="request-detail-payment-title">Payment</h3>
        </div>
        
        <div className="stripe-container">
          <stripe-buy-button
            buy-button-id={stripeKey}
            publishable-key="pk_live_51Nv18TLAddGNp2WwyHppJXbkKojo35b9mEWwkbgalp9OdUJFcbuNL44MI2vFeG4kNbs3ruD1zwumOqA1mUacdqGL0088dDI51h"
          >
          </stripe-buy-button>
        </div>
      </div>
      <div className="request-detail-call request-detail-table">
        <div className="request-detail-call-header">
          <h3 className="request-detail-call-title">Call Summary</h3>
          {isAdmin && <button className="request-detail-call-edit-btn"  onClick={onClickEditCall}> 
            {!isEditCall && <img className="request-detail-call-edit-image" src="image/account-payment-edit.png" alt="edit"/>}
            <span className="request-detail-call-edit-text">{isEditCall ? 'Save' : 'Edit'}</span>
          </button>}
        </div>
        <div className="request-detail-call-content">
          <span className="request-detail-call-label">Scheduled Time:</span>
          <input type="text" 
            ref={zoomDateRef}
            className="request-detail-call-value" 
            disabled={!isEditCall} value={zoomDatetime || ''} 
            onChange={e => setZoomDatetime(e.target.value)}
            onFocus={e => { 
              if(!zoomDatetime) {
                e.target.value = new Date().toLocaleString()
              }
              e.target.select()
            }}>
          </input>
          <span className="request-detail-call-line-break"></span>
          <span className="request-detail-call-label">Calendarly Link:</span>
          {!isEditCall && <a href={calendarlyLink}  target="_blank">{calendarlyLink}</a> }
          {isEditCall && <input type="url" className="request-detail-call-value" 
            disabled={!isEditCall} value={calendarlyLink || ''} 
            onChange={e => setCalendarlyLink(e.target.value)}
            onFocus={e => { 
              e.target.select()
            }}></input>}
          <span className="request-detail-call-line-break"></span>
          <span className="request-detail-call-label">Zoom Link:</span>
          {!isEditCall && <a href={zoomLink}  target="_blank">{zoomLink}</a> }
          {isEditCall && <input type="url" className="request-detail-call-value" 
            disabled={!isEditCall} value={zoomLink || ''} 
            onChange={e => setZoomLink(e.target.value)}
            onFocus={e => { 
              e.target.select()
            }}></input> }
        </div>
      </div>
    </div>
  );
}