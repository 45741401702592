export default function ProcessCard(props) {
  const style = {
    "backgroundColor": props.color
  };

  return (
    <div className="process-card">
      <div className="circle" style={style}>
        <img className="process-card-img" src={props.img} alt="process"/>
      </div>
      <div className="content"><span className="title">{props.title}</span>{props.content}</div>
    </div>
  );
}