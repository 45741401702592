import Collapsible from 'react-collapsible';
import RequestItemCollapsed from './request-item-collapsed.js';
import RequestDetail from "./request-detail";

export default function RequestItemM({request, selected, onRequestClick, user, onRequestStatusUpdate}) {
  return (
    <Collapsible open={selected} trigger={<RequestItemCollapsed key={request.id} request={request} selected={selected} onRequestClick={onRequestClick}/>}>
      <RequestDetail request={request} user={user} key={request.id} onRequestStatusUpdate={onRequestStatusUpdate}/>
    </Collapsible>
    
  );
}