import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Account from './account/account';
import Home from './home/home';
import Login from './login/login';
import UpdatePassword from './login/update-password.js';
import PageNotFound from './home/page-not-found.js';

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Account />} />
        <Route path="/login" element={<Login />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
);
  